import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import "intersection-observer"; // Polyfill for Intersection Observer
import "./AboutText.css";

function AboutText() {
  const aboutTextRef = useRef(null);
  const headerRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Animation for the header
          gsap.fromTo(
            headerRef.current,
            { y: 100, opacity: 0 },  // Initial state
            { y: 0, opacity: 1, duration: 1, ease: "power3.out" }  // End state
          );

          // Animation for the description
          gsap.fromTo(
            descriptionRef.current,
            { y: 100, opacity: 0 },  // Initial state
            { y: 0, opacity: 1, duration: 1, ease: "power3.out", delay: 0.5 }  // End state
          );

          observer.unobserve(entry.target); // Stop observing after animation plays
        }
      });
    });

    const currentAboutTextRef = aboutTextRef.current;
    if (currentAboutTextRef) {
      observer.observe(currentAboutTextRef);
    }

    return () => {
      if (currentAboutTextRef) {
        observer.unobserve(currentAboutTextRef);
      }
    };
  }, []);

  return (
    <div id="abouttext" ref={aboutTextRef}>
      <h1 ref={headerRef} className="abouttext-header">
        Kdo jsem
      </h1>
      <h3 ref={descriptionRef} className="abouttext-description">
        Jmenuji se Jan Schejbal. <br /> Programování se věnuji jíž téměř 6 let.
        Momentálně pracuji jako vývojář na volné noze. Nedávno jsem pracoval (a
        stále pracuji) na mobilní aplikaci ve Flutteru pro Huráligu a na několika e-commerce projektech, zejména
        backendu v PHP (Nette). 
        <br/>
        <br/>
        Rád se učím nové jazyky a frameworky. Více
        osobních projektů mám na svém githubu, kde se věnuji i ostatním jazykům.
        V případě zájmu o spolupráci mne neváhejte kontaktovat.
      </h3>
    </div>
  );
}

export default AboutText;
