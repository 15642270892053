import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import "./Showcase.css";

function Showcase() {
  const showcasetextRef = useRef(null);
  const decorTextRef = useRef(null);

  useEffect(() => {
    // Ensure elements are available
    if (showcasetextRef.current && decorTextRef.current) {
      // Animation for the showcase text
      gsap.fromTo(
        showcasetextRef.current,
        { y: 100, opacity: 0 },  // Initial state
        { y: 0, opacity: 1, duration: 2, ease: "power3.out" }  // End state
      );

      // Animation for the decor text
      gsap.fromTo(
        decorTextRef.current,
        { y: 100, opacity: 0 },  // Initial state
        { y: 0, opacity: 1, duration: 1, ease: "power3.out", delay: 0.5 }  // End state
      );
    }
  }, []);

  return (
    <div id="showcase">
      <h1 id="showcasetext" ref={showcasetextRef}>
        Pomáhám přeměnit Vaše nápady na <span>inovativní software.</span>
      </h1>
      <h1 id="decor-text" ref={decorTextRef}>Full Stack Developer</h1>
    </div>
  );
}

export default Showcase;
