import React, { useEffect } from "react";
import "./About.css";
import AboutText from "../components/About/AboutText";
import Experience from "../components/About/Experience";
import Education from "../components/About/Education";
import Certificates from "../components/About/Certificates";
import Footer from "../components/Footer";
import Header from "../components/Header";

function About() {
  useEffect(() => {
    document.body.style.backgroundColor = "#111111";
    document.body.style.color = "white";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    
    return () => {
      document.body.style.backgroundColor = "";
      document.body.style.color = "";
      // Reset při odchodu z komponenty
    };
  }, []);

  return (
    <>
      <Header />
      <AboutText />
      <Experience />
      <Education />
      <Certificates />
      <Footer />
    </>
  );
}

export default About;
