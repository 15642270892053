import React from 'react';
import emailjs from 'emailjs-com';
import './Form.css';

function Form() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm(
      'service_95lc32g', // ID vaší emailové služby
      'template_z8c5pqc', // ID vaší emailové šablony
      e.target,
      'I51XtgXQF9lWo6vce' // User ID, pokud máte nastavené
    )
    .then((result) => {
      console.log('Email successfully sent:', result.text);
      alert('Vaše zpráva byla úspěšně odeslána!');
    })
    .catch((error) => {
      console.error('Email sending failed:', error.text);
      alert('Něco se pokazilo, zkuste to prosím znovu.');
    });

    e.target.reset(); // Resetování formuláře po odeslání
  }

  return (
    <div id="form">
      <h1>Kontaktujte mě</h1>

      <form onSubmit={sendEmail}>
        <label htmlFor="name">Jméno a příjmení</label>
        <input type="text" id="name" name="user_name" required />

        <label htmlFor="email">Emailová adresa</label>
        <input type="email" id="email" name="user_email" required />

        <label htmlFor="message">Vaše zpráva</label>
        <textarea id="message" name="message" required />

        <button type="submit">Odeslat zprávu</button>
      </form>
    </div>
  );
}

export default Form;
