import React from "react";
import "./Card.css";
import { Link } from "react-router-dom";

function Card(props) {
  return (
    <div className="card-container">
      <Link to={props.link} className="card-link"></Link>
      <div
        className="content-wrapper"
        style={{
          backgroundColor: props.backgroundColor,
          color: props.textColor,
        }}
      >
        <div id="content">
          <h3>{props.text}</h3>
          <h4 className="description">{props.description}</h4>
        </div>
        <img src={props.image} className="image" id={props.imgId} alt="" />
      </div>
    </div>
  );
}

export default Card;
