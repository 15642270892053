import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Header.css";

function Header(props) {
  useEffect(() => {
    const links = document.querySelectorAll(".nav-link");

    function handleMouseEnter() {
      links.forEach((link) => link.classList.add("inactive"));
      this.classList.remove("inactive");
    }

    function handleMouseLeave() {
      links.forEach((link) => link.classList.remove("inactive"));
    }

    links.forEach((link) => {
      link.addEventListener("mouseenter", handleMouseEnter);
      link.addEventListener("mouseleave", handleMouseLeave);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("mouseenter", handleMouseEnter);
        link.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);

  return (
    <div id="header">
      <header>
        <Link to="/" id="logo-wrapper">
          <h1 id="logo">JS</h1>
        </Link>
        <ul>
          {/* <li>
            <Link to="/prace" className="nav-link">
              Práce
            </Link>
          </li> */}
          <li>
            <Link to="/o-mne" className="nav-link">
              O mně
            </Link>
          </li>
          <li>
            <Link to="/kontakt" className="nav-link">
              Kontakt
            </Link>
          </li>
        </ul>
      </header>
    </div>
  );
}

export default Header;
