import React from "react";
import "./Education.css";

function Education() {
  return (
    <div style={{ marginTop: "20%", paddingTop: "5px" }}>
      <h1 id="backgroundtext2">Vzdělání</h1>
      <div className="col">
        <h3>Univerzita Jana Evangelisty Purkyně v Ústí nad Labem</h3>
        <h5>Aplikovaná informatika (bakalářský)</h5>
        <p>září 2023 - dosud</p>
      </div>

      <div className="col">
        <h3>Střední průmyslová škola, Resslova 5, Ústí nad Labem</h3>
        <h5>
          Informační technologie, zaměření Počítačové sítě a programování
          (maturita)
        </h5>
        <p>září 2018 - červen 2022</p>
      </div>
    </div>
  );
}

export default Education;
