import "./App.css";
import {
  createBrowserRouter,
  ScrollRestoration,
  RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import About from "./pages/About";
import Contact from "./pages/Contact";
/* import Work from "./pages/Work";
import HuraLiga from "./pages/HuraLiga";
import { useEffect } from "react";
 */
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  /* {
    path: "/prace",
    element: <Work />,
  },
  {
    path: "/prace/huraliga",
    element: <HuraLiga />,
  }, */
  {
    path: "/o-mne",
    element: <About />,
  },
  {
    path: "/kontakt",
    element: <Contact />,
  },
]);

function App() {
  
  return (
    <div className="App">
      <RouterProvider router={router}>
        <ScrollRestoration />
      </RouterProvider>
    </div>
  );
}

export default App;
