import React, {useEffect} from "react";
import "./Footer.css";

function Footer(props) {
  useEffect(() => {
    const links = document.querySelectorAll(".footer-link");

    function handleMouseEnter() {
      links.forEach((link) => link.classList.add("inactive"));
      this.classList.remove("inactive");
    }

    function handleMouseLeave() {
      links.forEach((link) => link.classList.remove("inactive"));
    }

    links.forEach((link) => {
      link.addEventListener("mouseenter", handleMouseEnter);
      link.addEventListener("mouseleave", handleMouseLeave);
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("mouseenter", handleMouseEnter);
        link.removeEventListener("mouseleave", handleMouseLeave);
      });
    };
  }, []);

  return (
    <footer style={{ width: "100vw", marginTop: "200px", backgroundColor: props.backgroundColor, color: props.textColor }}>
      <div id="left">
        <h1>Těším se na naši spolupráci.</h1>
        <h6>@ 2024 Jan Schejbal</h6>
      </div>
      <div id="right">
        <ul>
          <li>
            <a href="mailto: janschejbal03@gmail.com" target="_blank" rel="noopener noreferrer" className="footer-link">Email</a>
          </li>
          <li>
            <a href="https://github.com/sejby" target="_blank" rel="noopener noreferrer" className="footer-link">GitHub</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/jan-schejbal/" rel="noopener noreferrer" target="_blank" className="footer-link">LinkedIn</a>
          </li>
        </ul>
        <h6>IČ: 10701303</h6>
        <h6>zapsán v živnostenském rejstříku</h6>
      </div>
    </footer>
  );
}

export default Footer;
