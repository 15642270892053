import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import "intersection-observer"; // Polyfill for Intersection Observer
import "./Certificates.css";
import PDF from "../../assets/certifikaty.pdf";

function Certificates() {
  const backgroundtext3Ref = useRef(null);
  const text3Ref = useRef(null);
  const buttonRef = useRef(null);
  const backgroundtext4Ref = useRef(null);
  const text4Ref = useRef(null);
  const button2Ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          !entry.target.classList.contains("animated")
        ) {
          entry.target.classList.add("animated");

          gsap.fromTo(
            entry.target,
            { y: 50, opacity: 0 }, // Initial state
            { y: 0, opacity: 1, duration: 1, ease: "power3.out" } // End state
          );

          observer.unobserve(entry.target); // Stop observing after animation plays
        }
      });
    });

    // Observe each element
    if (backgroundtext3Ref.current)
      observer.observe(backgroundtext3Ref.current);
    if (text3Ref.current) observer.observe(text3Ref.current);
    if (buttonRef.current) observer.observe(buttonRef.current);
    if (backgroundtext4Ref.current)
      observer.observe(backgroundtext4Ref.current);
    if (text4Ref.current) observer.observe(text4Ref.current);
    if (button2Ref.current) observer.observe(button2Ref.current);

    return () => {
      // Cleanup function
      if (backgroundtext3Ref.current)
        observer.unobserve(backgroundtext3Ref.current);
      if (text3Ref.current) observer.unobserve(text3Ref.current);
      if (buttonRef.current) observer.unobserve(buttonRef.current);
      if (backgroundtext4Ref.current)
        observer.unobserve(backgroundtext4Ref.current);
      if (text4Ref.current) observer.unobserve(text4Ref.current);
      if (button2Ref.current) observer.unobserve(button2Ref.current);
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <>
      <div>
        <h1 id="backgroundtext3" ref={backgroundtext3Ref}>
          Projekty
        </h1>
        <h5 id="text3" ref={text3Ref}>
          Programování je mým dlouhodobým koníčkem, tak se jím zabývám i ve
          volném čase. Většinou si zkouším nové jazyky, frameworky, technologie.
          Baví mě rozšiřovat si obzory. Některé mé projekty najdete na mém
          GitHubu.
        </h5>
        <a
          href="https://www.github.com/Sejby"
          target="_blank"
          rel="noreferrer noopener"
        >
          <button className="button" ref={buttonRef}>
            Odkaz na můj github
          </button>
        </a>
      </div>

      <div>
        <h1 id="backgroundtext4" ref={backgroundtext4Ref}>
          Certifikace
        </h1>
        <h5 id="text4" ref={text4Ref}>
          Během mých studií jsem obdržel certifikáty od společnosti Cisco
          Systems, která je největším dodavatelem síťové techniky na světě.
        </h5>
        <a href={PDF} target="_blank" rel="noreferrer noopener">
          <button className="button2" ref={button2Ref}>
            Certifikáty od společnosti Cisco
          </button>
        </a>
      </div>
    </>
  );
}

export default Certificates;
