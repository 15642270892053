import React from "react";
import "./Experience.css";

function Experience() {
  return (
    <div style={{ marginTop: "20%", paddingTop: "5px" }}>
      <h1 id="backgroundtext">Zkušenosti</h1>
      <div className="col">
        <h3>
          DigiLabs Software s.r.o. <span>Full stack vývojář</span>
        </h3>
        <h5>
          PHP (Nette) <br />
          CSS <br />
          MySQL
        </h5>
        <p>červenec 2024 - dosud</p>
      </div>
      <div className="col">
        <h3>
          HuráLiga s.r.o. <span>Full stack vývojář</span>
        </h3>
        <h5>
          Samostatný vývoj mobilní aplikace <br />
          Flutter (Dart) <br /> PHP REST API <br /> MySQL
        </h5>
        <p>březen 2024 - dosud</p>
      </div>

      <div className="col">
        <h3>
          OBVision s.r.o. <span>Backend vývojář</span>
        </h3>
        <h5>
          Vývoj admin sekce pro e-commerce <br /> Nette (PHP) <br /> MySQL
        </h5>
        <p>březen 2024 - dosud</p>
      </div>

      <div className="col">
        <h3>
          Krásné koupelny <span>Správce e-shopu</span>
        </h3>
        <h5>
          Obsluha správy e-shopu <br /> Pluginy do shoptetu (PHP) <br />{" "}
          Marketing (videoprodukce)
        </h5>
        <p>únor 2023 - září 2023</p>
      </div>
    </div>
  );
}

export default Experience;
