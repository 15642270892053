import React, { useEffect } from "react";
import "./Contact.css";
import Form from "../components/Contact/Form";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Contact() {
  useEffect(() => {
    document.body.style.backgroundColor = "#fff";
    document.body.style.color = "black";

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    return () => {
      document.body.style.backgroundColor = "";
      document.body.style.color = ""; // Reset při odchodu z komponenty
    };
  }, []);

  return (
    <>
      <Header />
      <Form />
      <Footer />
    </>
  );
}

export default Contact;
