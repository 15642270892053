import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import "intersection-observer"; // Polyfill for Intersection Observer
import "./DecorText.css";

function DecorText(props) {
  const textRef = useRef(null);
  const descriptionRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Animation for the text
          gsap.fromTo(
            textRef.current,
            { y: 100, opacity: 0 },  // Initial state
            { y: 0, opacity: 1, duration: 1, ease: "power3.out" }  // End state
          );

          // Animation for the description
          gsap.fromTo(
            descriptionRef.current,
            { y: 100, opacity: 0 },  // Initial state
            { y: 0, opacity: 1, duration: 1, ease: "power3.out", delay: 0.5 }  // End state
          );

          observer.unobserve(entry.target); // Stop observing after animation plays
        }
      });
    });

    const currentContainerRef = containerRef.current;
    if (currentContainerRef) {
      observer.observe(currentContainerRef);
    }

    return () => {
      if (currentContainerRef) {
        observer.unobserve(currentContainerRef);
      }
    };
  }, []);

  return (
    <div 
      id="text" 
      ref={containerRef} 
      style={{ textAlign: props.textAlign, width: '100vw' }}
    >
      <h1 ref={textRef}>{props.text}</h1>
      <h4 
        ref={descriptionRef} 
        style={{ paddingLeft: props.paddingLeft, paddingRight: props.paddingRight }}
      >
        {props.description}
      </h4>
    </div>
  );
}

export default DecorText;
